<template>
  <div class="overflow-x-hidden">
    <v-container grid-list-xs>
      <div v-if="loading">
        <div class="flex flex-column text-center justify-center my-16">
          <!-- <v-progress-circular indeterminate color="grey"></v-progress-circular> -->
          <h2 class="grey--text source my-auto">Loading ...</h2>
        </div>
      </div>
      <div v-else>
        <div>
          <div class="flex justify-center">
            <div style="position: absolute; top: 2%">
              <v-toolbar-title>
                <a
                  :href="`https://v2.getorders.app/${invoice.link}`"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color:black"
                >
                  <strong>
                    {{ invoice.outlet_name }}
                  </strong>
                  <p class="branch-title" v-if="invoice.branch_name">
                    ({{ invoice.branch_name }})
                  </p>
                </a>
              </v-toolbar-title>
            </div>
          </div>
          <div class="flex justify-end">
            <div style="position: absolute; top: 2%">
              <v-btn icon color="black" @click="print" class="">
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <v-card rounded="lg" class="mb-5" id="image">
          <div class="flex flex-column">
            <div class="flex flex-column text-center mt-3">
              <span> Order No</span>
              <h4
                :class="`${
                    invoice.status == 4
                    ? 'red--text'
                    : 'leaf--text'
                } `"
              >
                {{ invoice.invoice_id }}
              </h4>
            </div>
            <div class="my-0 flex justify-center">
              <v-img max-width="300" :src="changePic()"></v-img>
            </div>
            <div
              :class="`
                mt-15
                my-6
                flex
                justify-center
                rounded-lg
                mx-2
                py-2
                ${'grey lighten-2'}`"
            >
              <h4
                :class="`${
                    invoice.status == 4
                    ? 'red--text'
                    : 'leaf--text'
                } ml-2 mt-1`"
              >
                {{ changeMessage() }}
              </h4>
            </div>
          </div>
        </v-card>
        <v-card
          class="mt-3 mb-5"
          id="invoice"
          style="display: none"
          :color="
            invoice.status == 2
              ? 'green'
              : invoice.status == 6
              ? 'blue'
              : invoice.status == 3 || invoice.status == 4
              ? 'purple'
              : 'orange'
          "
        >
          <v-row>
            <v-col cols="6" class="source white--text" style="line-height: 1">
              <h4 class="ml-2 mb-2">{{ date(invoice.createdAt) }}</h4>
              <h4 class="ml-2 mb-2">{{ time(invoice.createdAt) }}</h4>
            </v-col>
            <v-col cols="6" class="source white--text">
              <div class="line">
                <h5 class="">invoice</h5>
                <h4>{{ invoice.invoice_id }}</h4>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mb-5" v-if="invoice.delivery">
          <v-row
            v-if="invoice.delivery.provider && invoice.delivery.provider !== ''"
          >
            <v-col
              cols="12"
              class="mx-3 source"
              v-if="invoice.delivery_detail && invoice.delivery.provider"
            >
              <div v-if="invoice.delivery.provider == 'own_delivery'">
                <h4>Personal Delivery</h4>
              </div>
              <div v-else-if="invoice.delivery.provider == 'speedy'">
                <h5 class="grey--text">Delivery Detail</h5>
                <!-- <div class="flex my-2">
                  <v-avatar
                    size="50"
                    color="red"
                    v-if="invoice.delivery_detail.courier.photo_url"
                  >
                    <img
                      :src="invoice.delivery_detail.courier.photo_url"
                      alt="alt"
                    />
                  </v-avatar>
                  <div class="flex flex-column ml-4 line">
                    <h4>{{ invoice.delivery_detail.courier.name }}</h4>
                    <h4>{{ invoice.delivery_detail.courier.phone }}</h4>
                    <h4>{{ invoice.delivery.provider }}</h4>
                  </div>
                </div> -->
                <v-divider class="my-3 mx-4"></v-divider>
                <!-- <div class="flex justify-center">
                  <a
                    href="http://www.google.com.my"
                    class="
                      text-decoration-none
                      indigo--text
                      source
                      font-weight-bolds
                    "
                    target="_blank"
                  >
                    Track Delivery</a
                  >
                </div> -->
              </div>
              <div v-else-if="invoice.delivery.provider == 'bungkusit'">
                <h5 class="grey--text">Delivery Detail</h5>
                <div
                  class="flex my-2"
                  v-if="
                    invoice.delivery_detail.status !== 'error' &&
                    invoice.delivery_detail.courier != null
                  "
                >
                  <v-avatar size="50" color="red">
                    <img
                      :src="invoice.delivery_detail.courier.photo_url"
                      alt="alt"
                    />
                  </v-avatar>
                  <div class="flex flex-column ml-4 line">
                    <h4>{{ invoice.delivery_detail.courier.name }}</h4>
                    <h4>{{ invoice.delivery_detail.courier.phone }}</h4>
                    <h4>{{ invoice.delivery.provider }}</h4>
                  </div>
                </div>
                <v-divider class="my-3 mx-4"></v-divider>
                <!-- <div class="flex justify-center">
                  <a
                    href="http://www.google.com.my"
                    class="
                      text-decoration-none
                      indigo--text
                      source
                      font-weight-bolds
                    "
                    target="_blank"
                  >
                    Track Delivery</a
                  >
                </div> -->
              </div>
              <div v-else-if="invoice.delivery.provider == 'lalamove'">
                <h5 class="grey--text">Delivery Detail</h5>
                <div
                  v-if="
                    invoice.delivery_detail.order_detail.status == 'error' ||
                    invoice.delivery_detail.order_detail.status == 'EXPIRED'
                  "
                >
                  <h4 class="red--text">
                    {{ invoice.delivery_detail.rider_detail.message }}
                  </h4>
                </div>
                <div class="flex my-2" v-else>
                  <v-avatar size="50" color="white">
                    <img
                      :src="invoice.delivery_detail.rider_detail.photo"
                      alt="alt"
                    />
                  </v-avatar>
                  <div class="flex flex-column ml-4 line">
                    <h4>{{ invoice.delivery_detail.rider_detail.name }}</h4>
                    <h4>{{ invoice.delivery_detail.rider_detail.phone }}</h4>
                    <h4>
                      {{ invoice.delivery_detail.rider_detail.plateNumber }}
                    </h4>
                    <h4 class="orange--text">
                      {{ invoice.delivery.provider }}
                    </h4>
                  </div>
                </div>
                <v-divider class="my-3 mx-4"></v-divider>
                <div class="flex justify-center">
                  <a
                    :href="invoice.delivery_detail.order_detail.shareLink"
                    class="
                      text-decoration-none
                      indigo--text
                      source
                      font-weight-bolds
                    "
                    target="_blank"
                  >
                    Track Delivery</a
                  >
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mb-5" v-if="invoice.pickupAddress">
          <div class="flex flex-column pa-3">
            <h5 class="text-uppercase grey--text mb-2">Pickup</h5>
            <v-card outlined color="deep-orange-outline" class="pa-2">
              <div class="flex flex-column">
                <h5>Collect At/After : {{ timePickup() }}</h5>
                <div class="line">
                  <div class="flex">
                    <h4>
                      {{ invoice.outlet_name }}
                    </h4>
                    <h4 class="ml-1" v-if="invoice.branch_name">
                      ({{ invoice.branch_name }})
                    </h4>
                  </div>
                  <h4>
                    {{ invoice.pickupAddress.address }}
                  </h4>
                  <h4>
                    {{ invoice.pickupAddress.address2 }}
                  </h4>
                  <h4>
                    {{ invoice.pickupAddress.postcode }}
                  </h4>
                  <v-btn
                    color="success"
                    class="mt-3"
                    text
                    :href="`tel:${invoice.pickupAddress.phone}`"
                  >
                    <v-icon>mdi-whatsapp</v-icon>
                    <span class="ml-2">{{ invoice.pickupAddress.phone }}</span>
                  </v-btn>
                  <v-btn
                    color="info"
                    class="mt-3"
                    text
                    @click="mapLink(invoice.pickupAddress)"
                  >
                    <v-icon>mdi-map-marker</v-icon>
                    <span class="ml-2">Location</span>
                  </v-btn>
                </div>
              </div>
            </v-card>
          </div>
        </v-card>
        <v-card class="mb-5" v-if="invoice.guest">
          <v-row>
            <v-col cols="12" class="ml-3 source">
              <div class="px-2">
                <h5 class="grey--text">Receiver</h5>
                <div class="line">
                  <h4>
                    {{ invoice.guest.name }}
                  </h4>
                  <h4>
                    {{ invoice.guest.address }}
                  </h4>
                  <h4>
                    {{ invoice.guest.address2 }}
                  </h4>
                  <h4>
                    {{ invoice.guest.postcode }}
                  </h4>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <!-- <v-card
          class="mb-5"
          v-if="
            invoice.delivery.provider == 'lalamove' &&
            invoice.delivery_detail.rider_detail.name
          "
        >
          <v-row>
            <v-col cols="12" class="ml-3 source">
              <div class="px-2">
                <h5 class="grey--text">Rider Detail</h5>
                <div class="line">
                  <h4 class="orange--text">
                    {{ invoice.delivery.provider }}
                  </h4>
                  <h4>
                    {{ invoice.delivery_detail.rider_detail.name }}
                  </h4>
                  <h4>
                    {{ invoice.delivery_detail.rider_detail.phone }}
                  </h4>
                  <h4>
                    {{ invoice.delivery_detail.rider_detail.plateNumber }}
                  </h4>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card> -->
        <v-card class="mb-5 pb-2">
          <v-row>
            <v-col cols="12" class="ml-3 source">
              <h5 class="grey--text mb-2 ml-2">Order Summary</h5>
              <div v-for="(v, i) in invoice.cart" :key="i">
                <v-window v-model="step">
                  <v-window-item :value="1">
                    <v-row>
                      <v-col cols="6">
                        <div class="flex flex-column source line">
                          <h3 class="mt-2 mb-2">
                            {{ v.name }}
                            <!-- {{ v.variant }} -->
                          </h3>
                          <div v-if="v.modifiers.length > 0" class="">
                            <span
                              class="flex"
                              v-for="(val, ind) in v.modifiers"
                              :key="ind"
                            >
                              <h5>{{ showMod(val) }}</h5>
                            </span>
                          </div>
                          <div
                            v-if="v.variant.length > 0"
                            class=""
                          >
                            <span
                              class="flex"
                              v-for="(values, indexs) in v.variant"
                              :key="indexs"
                            >
                              <h5>{{ showAmt(values) }}</h5>
                            </span>
                          </div>
                          <span class="flex" v-if="v.remarks && v.remarks !== ''"
                            ><h5>Remarks:</h5>
                            <h5 class="blue-grey--text ml-1">
                              {{ v.remarks }}
                            </h5></span
                          >
                        </div>
                      </v-col>
                      <v-col cols="2" class="flex justify-end">
                        <v-btn color="grey lighten-2" fab elevation="0" small>
                          <h4>x{{ v.quantity }}</h4>
                        </v-btn>
                      </v-col>
                      <v-col cols="3">
                        <span class="flex justify-end">
                          <h4 class="mt-2">
                            RM {{ parseFloat(showUnitPrice(v)).toFixed(2) }}
                          </h4>
                        </span>
                      </v-col>
                    </v-row>
                  </v-window-item>

                  <v-window-item :value="2">
                    <div v-if="!hasProductRating">
                      <v-row>
                        <v-col cols="6">
                          <h3 class="mt-2 mb-2">
                            {{ v.name }}
                            <!-- {{ v.variant }} -->
                          </h3>
                          <div v-if="v.modifiers.length > 0" class="">
                            <span
                              class="flex"
                              v-for="(val, ind) in v.modifiers"
                              :key="ind"
                            >
                              <h5>{{ showMod(val) }}</h5>
                            </span>
                          </div>
                        </v-col>
                        <v-col class="my-auto mr-6">
                          <div class="">
                            <v-rating v-model="productRating[i].rating" class="text-end">
                              <template v-slot:item="props">
                                <v-icon
                                  :color="props.isFilled ? genColor(props.index) : 'grey lighten-1'"
                                  medium
                                  class="rating-icon"
                                  @click="props.click"
                                >
                                  {{ props.isFilled ? 'mdi-star' : 'mdi-star' }}
                                </v-icon>
                              </template>
                            </v-rating>
                          </div>
                        </v-col>
                      </v-row>   
                    </div>
                  </v-window-item>
                </v-window>
              </div>
              <v-row v-if="invoice.status == 8">
                  <v-col cols="12" class="justify-end align-end text-end" end>
                    <div class="mr-5">
                      <div v-if="step === 1">
                        <v-btn
                          text
                          color="teal"
                          @click="hasProductRating ? snackbar = true : hasRating ? snackbar = true : step++"
                        >
                          Leave a rating
                        </v-btn>
                      </div>
                  
                      <div v-if="step ===2">
                        <v-btn
                          text
                          @click="step--,postProductReview()"
                        >
                          Submit Rating
                        </v-btn>
                      </div>
                    </div>
                    
                  </v-col>
                  <div class="text-center">
                    <v-snackbar
                      v-model="snackbar"
                      timeout="5000"
                      top
                    >
                      Your review has been submitted
                      <template v-slot:action="{ attrs }">
                        <v-btn
                          color="blue"
                          text
                          v-bind="attrs"
                          @click="snackbar = false"
                        >
                          Close
                        </v-btn>
                      </template>
                    </v-snackbar>
                  </div>
                </v-row>
            </v-col>
          </v-row>
          <v-divider class="mt-4 mx-2"></v-divider>
          <div class="pa-3 flex flex-column ma-2">
            <div class="flex flex-column">
              <div class="flex justify-space-between" v-if="invoice.payment">
                <h5>Payment Type</h5>
                <h5>{{ invoice.payment ? "Online Banking" : "" }}</h5>
              </div>
              <div class="flex justify-space-between ml-3">
                <h5>Subtotal</h5>
                <h5>{{ cartTotal(invoice.cart) }}</h5>
              </div>
              <div
                class="flex justify-space-between ml-3"
                v-if="invoice.delivery_amount"
              >
                <h5>Delivery Charges ({{invoice.delivery.service}})</h5>
                <!-- <h5>+ {{ parseFloat(invoice.delivery_amount).toFixed(2) }}</h5> -->
                <h5>+ {{ parseFloat((invoice.total_delivery) ? invoice.total_delivery : invoice.delivery_amount).toFixed(2) }}</h5>
              </div>
              <div
                class="flex justify-space-between ml-3"
                v-if="invoice.voucher"
              >
                <h5>Voucher {{ invoice.voucher.delivery ? '(Delivery)' : '(Item)'}}</h5>
                <h5>{{ showVoucher(invoice.voucher) }}</h5>
              </div>
              <div class="flex justify-space-between ml-3" v-if="invoice.tax">
                <h5>SST Tax({{ invoice.tax.value }}%)</h5>
                <h5>+ {{ parseFloat(invoice.tax.amount).toFixed(2) }}</h5>
              </div>
              <div
                class="flex justify-space-between ml-3"
                v-if="invoice.packaging_amount"
              >
                <h5>Packaging Fee</h5>
                <h5>+ {{ parseFloat(invoice.packaging_amount).toFixed(2) }}</h5>
              </div>
            </div>
            <div class="flex justify-space-between">
              <h3 class="mt-2">Grand Total</h3>
              <h2>RM {{ parseFloat(invoice.grand_total).toFixed(2) }}</h2>
            </div>
          </div>
        </v-card>
        <!-- <v-card v-if="invoice.status == 8" class="mb-5 pb-2">
          <v-row v-if="invoice.review == null">
            <v-col v-if="!hasRating" cols="12" class="ml-3 source">
              <v-row>
                <v-col cols="12">
                  <div class="flex flex-column source line">
                    <div class="px-2">
                      <h5 class="grey--text pb-2">Add Review</h5>
                          <v-rating v-model="newRating.rating">
                            <template v-slot:item="props">
                              <v-icon
                                :color="props.isFilled ? genColor(props.index) : 'grey lighten-1'"
                                large
                                @click="props.click"
                                class="rating-icon"
                              >
                                {{ props.isFilled ? 'mdi-star' : 'mdi-star' }}
                              </v-icon>
                            </template>
                          </v-rating>
                        <div class="rules">
                          <v-text-field 
                            label="Title" 
                            class="input-rate"
                            v-model="newRating.title"
                            color="green"
                            >
                          </v-text-field>
                          <v-textarea
                            label="Comments"
                            placeholder="Please leave a review..."
                            outlined
                            class="text-area"
                            rows="4"
                            v-model="newRating.comment"
                            color="green">
                          </v-textarea>
                        </div>
                          <div class="rate-btn">
                            <v-btn
                              block
                              color="leaf"
                              dark
                              large
                              elevation="5"
                              rounded
                              @click="postReview"
                              >
                              <span>Post a review</span>
                            </v-btn>
                          </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="hasRating" cols="12" class="ml-3 source">
              <div class="px-2">
                  <div class="line pb-2">
                    <v-rating v-bind:value="oldRating">
                      <template v-slot:item="props">
                        <v-icon
                          :color="props.isFilled ? genColor(props.index) : 'grey lighten-1'"
                          large
                          class="rating-icon"
                        >
                          {{ props.isFilled ? 'mdi-star' : 'mdi-star' }}
                        </v-icon>
                      </template>
                    </v-rating>
                  </div>
                  <div class="rules" color="secondary" width="200px">
                    <p>Thank You for the Review !</p>
                  </div>
              </div>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12" class="ml-3 source">
              <div class="px-2">
                <h5 class="grey--text pb-2">Thank You for the Review!</h5>
                  <div class="line pb-2">
                    <v-rating v-model="invoice.review.rating">
                      <template v-slot:item="props">
                        <v-icon
                          :color="props.isFilled ? genColor(props.index) : 'grey lighten-1'"
                          large
                          class="rating-icon"
                        >
                          {{ props.isFilled ? 'mdi-star' : 'mdi-star' }}
                        </v-icon>
                      </template>
                    </v-rating>
                  </div>
                  <div class="rules mx-auto" color="secondary" width="200px">
                    <p>{{ invoice.review.title }}</p>
                    <p>{{ invoice.review.comment }}</p>
                  </div>
              </div>
            </v-col>
          </v-row>
        </v-card> -->
      </div>
    </v-container>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  name: "invoice",

  data() {
    return {
      colors: ['orange', 'orange', 'orange', 'orange', 'orange'],
      oldRating: null,
      hasRating: false,
      newRating: {
        guest_id: "",
        order_id: "",
        rating: null,
        comment: "",
        title: "",
        fire_id: "",
        publish: false,
      },
      defaultButtonText: '',
      selectedFile: null,
      isSelecting: false,
      image_set: require("@/assets/img/coffee.svg"),
      message: "Order Created",
      status: 1,
      hasProductRating: false,
      step: 1,
      productsReview: {
        guest_id: "",
        order_id: "",
        fire_id: "",
        details: []
      },
      productRating: [],
      snackbar: false,
    };
  },

  watch: {
    invoice: function () {
      if(this.invoice){
        this.invoice.cart.map((p) => {
          this.productRating.push(
            {
              product_id: p.id,
              rating: null,
            }
          )
        })
        this.$store.dispatch("fetchReview",this.invoice.cart[0].id)
      }
    },
    productReview: function() {
      this.checkHasRating()
    }
  },

  computed: {
    ...mapGetters({
      franchise: "getFranchise",
      invoice: "getInvoice",
      loading: "getLoading",
      productReview: "getProductReview",
    }),
    buttonText() {
      return this.selectedFile ? this.selectedFile.name : this.defaultButtonText
    },
  },

  methods: {
    date(i) {
      return dayjs(i).format("MMM DD, YYYY");
    },

    showUnitPrice(v) {
      let mods = 0;

      if (v.modifiers.length > 0) {
        mods = v.modifiers.reduce((sum, item) => sum = sum + item.amount, 0);
      }

      return (mods != 0) ? v.unit_price - mods : v.unit_price;
    },

    showMod(mod) {
      var prefix = mod.mode == 1 ? "+ " : mod.mode == 2 ? "- " : "",
        suffix = mod.mode == 3 ? "% " : "";
      var amt =
        mod.amount > 0 ? ` ${prefix} RM${mod.amount.toFixed(2)}${suffix}` : "";
      return `${mod.name}${amt}`;
    },

    showAmt(vars) {
      if (typeof vars.price == "string") {
        vars.price = parseFloat(vars.price);
      }

      return `${vars.name}`;
    },
    
    genColor (i) {
        return this.colors[i]
    },

    onButtonClick() {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true })

      this.$refs.uploader.click()
    },

    onFileChanged(e) {
      this.selectedFile = e.target.files[0]
      
      // do something
    },

    postReview() {
      // console.log(this.newRating)
      if (this.newRating.rating == null) {
        alert("Please give a star rating");
      } else {
        this.newRating.guest_id = this.invoice.guest._id;
        this.newRating.order_id = this.invoice._id;
        this.newRating.fire_id = this.invoice.fire_id;
        this.newRating.publish = true;
        // console.log(this.newRating)
        this.$store.dispatch("saveReview", this.newRating);
        this.hasRating = true;
        this.oldRating = this.newRating.rating;
      }
    },

    postProductReview() {
      this.productsReview.guest_id = this.invoice.guest._id
      this.productsReview.order_id = this.invoice._id
      this.productsReview.fire_id = this.invoice.fire_id
      if(this.invoice.cart.length == this.productRating.length){
        this.productsReview.details = this.productRating
        this.$store.dispatch("saveProductsReview",this.productsReview)
        this.hasRating = true
      }
    },

    checkHasRating() {
      this.productReview.map((p) => {
        if(p.order === this.invoice._id){
          this.hasRating = true
        }
      })
    },

    timePickup() {
      return dayjs(this.invoice.createdAt).add(15, "minute").format("h:mmA");
    },

    mapLink(addr) {
      let address = addr.address || "";
      address += addr.address2 || "";
      address += addr.postcode || "";
      // return `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}`;
      window.open(
        `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
          address
        )}`,
        "_blank"
      );
    },

    changePic() {
      if (this.invoice.status == 1) {
        return require("@/assets/img/order-created.png");
      }
      if (this.invoice.status == 2) {
        return require("@/assets/img/order-created.png");
      }
      if (this.invoice.status == 3) {
        return require("@/assets/img/packing.svg");
      }
      if (this.invoice.status == 4) {
        return require("@/assets/img/order-cancelled.png");
      }
      if (this.invoice.status == 5) {
        return require("@/assets/img/order-processed.png");
      }
      if (this.invoice.status == 6) {
        return require("@/assets/img/order-delivery.png");
      }
      if (this.invoice.status == 7) {
        return require("@/assets/img/order-pickup.png");
      }
      if (this.invoice.status == 8) {
        return require("@/assets/img/order-delivered.png");
      }
    },

    changeMessage() {
      switch (this.invoice.status) {
        case 1:
          return "Order Created";
        case 2:
          return "Order Created";
        case 3:
          return "Your order have been accepted";
        case 4:
          return "Your order have been cancelled";
        case 5:
          return "Your order has been accepted and currently being processed";
        case 6:
          return "Your order is currently out for delivery";
        case 7:
          return "Your order is ready to be pickup";
        case 8:
          return "Your order is completed";
      }
    },

    count() {
      return parseFloat(
        this.invoice.cart.reduce((sum, item) => {
          var mods = item.modifiers.reduce((sum, mod) => {
            var amt = parseFloat(mod.amount);
            var actual = mod.mode == 1 ? amt : mod.mode == 2 ? -amt : 0;
            return parseFloat(sum) + actual;
          }, 0);
          return (
            parseFloat(sum) +
            (parseFloat(item.unit_price) + parseFloat(mods)) * item.quantity
          );
        }, 0)
      ).toFixed(2);
    },

    cartTotal(v) {
      let a = v.reduce((sum, item) => {
        return (
          parseFloat(sum) + (parseFloat(item.unit_price))
        );
      }, 0);
      return parseFloat(a).toFixed(2);
    },

    showVoucher(v) {
      let prefix;
      if (v) {
        if (v.discount) {
          prefix = `- ${v.discount}%`;
        }
        console.log(this.invoice, 'delivery');
        if (v.delivery) {
          // prefix = `- ${parseFloat(v.delivery).toFixed(2)}`;
          prefix = (v.delivery > this.invoice.total_delivery) ? `${parseFloat(0.00).toFixed(2)}` : `- ${parseFloat(v.delivery).toFixed(2)}` ;
        }
        // if (this.voucher.delivery) {
        //   sum -= parseFloat($session.delivery_fees);
        // }
        if (v.deduct) {
          prefix = `- ${parseFloat(v.deduct).toFixed(2)}`;
        }
      }
      return prefix;
    },

    time(i) {
      return dayjs(i).format("h:MM A");
    },

    print() {
      var x = document.getElementById("image");
      var y = document.getElementById("invoice");
      setTimeout(() => {
        x.style.display = "none";
        y.style.display = "block";
        window.print();
        x.style.display = "block";
        y.style.display = "none";
      }, 500);
    },

  },

  beforeMount() {
    let k = window.location.pathname.split("/");
    let l = k[2].split("%");
    if (k[2].split("%").length > 1) {
      document.location.href = `${window.location.origin}/i/${l[0]}`;
    }
    this.$store.dispatch("startLoad");
    this.$store.dispatch("fetchInvoice", this.$route.params.page);
    // if (i.cart.modifiers > 0) {
    //   var calcMods = i.cart.reduce(function(sum, item) {
    //       return sum = sum+item.modifiers.amount;
    //   },0);
    // }
    // console.log(calcMods, 'invoice')
    // console.log(this.invoice)
    // if (this.invoice.review) {
      // console.log(this.invoice.review.rating)
    //   this.oldRating = this.invoice.review.rating
    // }
  },
};
</script>
<style scoped>
.deep-orange-outline {
  background-color: white !important;
  border: #607d8b solid 1px !important ;
}

.branch-title {
  display: inline;
}

.rating-icon {
  padding: 0;
}

.v-icon-camera {
  margin-center: 8px;
}

.camera-btn {
  background-color: transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: 2px #F0F0F0 dashed;
  width:95%;
}

.rules, .rate-now, .rate-btn {
  width:95%;
}

.rate-btn {
  font-weight:bold;
}
</style>
